.values-carousel .swiper-pagination {
  width: fit-content;
  /* background-color: #252525; */
}

.slide {
  position: absolute;
  inset: 0;
  font-size: 2rem;
}

.slide1 {
  opacity: 0;
}

.slide2 {
  opacity: 0;
}

.slide3 {
  opacity: 0;
}

.background-gradient-img {
  position: relative;
  z-index: 1;
}

.background-gradient-img::before {
  content: '';
  background: url(../../assets/globals/bg-circle-top.png);
  mix-blend-mode: color-dodge;
  filter: grayscale(1) contrast(1.2);
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  background-size: 45%, cover;
  background-position: top left;
  background-repeat: no-repeat;
  z-index: -1;
  /* filter: grayscale(1); */
}

.stories-carousel {
  position: relative;
}

.stories-carousel::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 60px;
  z-index: 10;
  background: linear-gradient(to right, #000, transparent);
}

.stories-carousel::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  width: 60px;
  z-index: 10;
  background: linear-gradient(to left, #000, transparent);
}

@media (max-width: 786px) {
  .stories-carousel::before,
  .stories-carousel::after {
    width: 10px;
  }
}

.text-appears {
  transition: all 400ms cubic-bezier(0.2, 0, 0, 1);
}

.banner-animation .banner-text-heding span .text-appears:nth-child(13) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 25ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(10) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 50ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(5) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 75ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(14) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 100ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(6) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 125ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(11) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 150ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(7) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 175ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(4) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 200ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(2) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 25ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(15) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 50ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(8) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 100ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(12) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 300ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(1) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 25ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(9) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 250ms;
}
.banner-animation .banner-text-heding span .text-appears:nth-child(3) {
  animation: fade-in-text 800ms cubic-bezier(0.2, 0, 0, 1) 75ms;
}
/* .banner-animation .banner-text-heding span .text-appears:nth-child(16) {
  opacity: 1;
  animation: fade-in-text 1000ms cubic-bezier(0.2, 0, 0, 1)  400ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(17) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  425ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(18) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  450ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(19) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  475ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(20) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  500ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(21) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  525ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(22) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  550ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(23) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  575ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(24) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  600ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(25) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  625ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(26) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  650ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(27) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  675ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(28) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  700ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(29) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  725ms;
}
.banner-animation .banner-text-heding .text-appears:nth-child(30) {
  opacity: 1;
  animation: fade-in-text 1000ms ease-in-out  750ms;
} */

@keyframes fade-in-text {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: translateY(0px);
  }
  50% {
    opacity: 0.5;
    filter: blur(4px);
    transform: translateY(-10px);
  }
  100% {
    opacity: 1 !important;
    filter: blur(0px);
    transform: translateY(0px);
  }
}
