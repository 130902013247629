.onplan-wrapper ::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

.onplan-wrapper ::-webkit-scrollbar-thumb {
  background-color: gray !important;
}

.onplan-wrapper ::-webkit-scrollbar-thumb:hover {
  background-color: gray !important;
}

.onplan-wrapper ::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.onplan-wrapper ::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

.swiper-scrollbar-drag {
  background: #0080ff;
}

.onplan-wrapper .vertical-pagination .swiper-pagination-bullet-active {
  width: 10px !important;
  height: 50px !important;
}

/* Onplan impact metrics table styles */

.impact-table {
  width: 100%;
  position: relative;
}
.impact-table::before {
  position: absolute;
  border-radius: 100%;
  inset: 0;
  margin: auto;
  filter: blur(140px);
  z-index: 0;
  content: '';
  height: 250px;
  width: 250px;
  background: rgba(128, 128, 128, 0.6);
}

.impact-table th {
  font-weight: 500;
}

.impact-table tbody td,
th {
  padding: 20px 20px 20px 0px;
}
@media (max-width: 768px) {
  .impact-table tbody td,
  th {
    padding: 10px 10px 10px 0px;
  }
}
.impact-table tbody tr {
  position: relative;
}
.impact-table tbody tr::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: linear-gradient(to right, black, gray, black);
}
.impact-table tbody tr:last-child::after {
  display: none;
}
