@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.infinite-carousel {
  background-color: black;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
}
.infinite-carousel::before,
.infinite-carousel::after {
  height: 100%;
  background: linear-gradient(to right, black 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  position: absolute;
  width: 40%;
  z-index: 2;
}
.infinite-carousel::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.infinite-carousel::before {
  left: 0;
  top: 0;
}
.infinite-carousel .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.infinite-carousel .elem {
  height: 100px;
  width: 250px;
}

/* Animation  */

.heading-wrapper-animation .pageName {
  opacity: 1 !important;
  transform: translateY(0);
  transition: all 1.2s ease 100ms;
}
.heading-wrapper-animation .title {
  opacity: 1 !important;
  transform: translateY(0);
  transition: all 1.2s ease 150ms;
}
.heading-wrapper-animation .subtitle {
  opacity: 1 !important;
  transform: translateY(0);
  transition: all 1.2s ease 200ms;
}

.paragraph-fade-in :nth-child(1) {
  opacity: 1;
  transform: translateY(0);
  transition: all 1.2s ease 100ms;
}
.paragraph-fade-in :nth-child(2) {
  opacity: 1;
  transform: translateY(0);
  transition: all 1.2s ease 150ms;
}
.paragraph-fade-in :nth-child(3) {
  opacity: 1;
  transform: translateY(0);
  transition: all 1.2s ease 200ms;
}
.paragraph-fade-in :nth-child(4) {
  opacity: 1;
  transform: translateY(0);
  transition: all 1.2s ease 250ms;
}

.benefit-nav-btns {
  border-radius: 30.959px;
  border: 0.789px solid #252525;
  background: #181818;
  box-shadow: 0px 0px 31.562px 5.523px rgba(114, 114, 114, 0);
}

.metric-card {
  position: relative;
  border-radius: 5.934px;
  border: 1.695px solid rgba(65, 65, 65, 0.62);
  background: #010101;
  box-shadow: 0px 0px 2.543px 1.695px rgba(174, 174, 174, 0.15) inset;
}
.metric-card .value-gradient {
  background-image: linear-gradient(
    93deg,
    rgba(207, 229, 255, 0.8) 6.27%,
    #fff 40.6%,
    rgba(207, 229, 255, 0.6) 78.71%
  );

  backdrop-filter: blur(7px);
}
.metric-card::after {
  content: '';
  position: absolute;
  left: -15%;
  right: auto;
  bottom: 0;
  height: 100%;
  width: 100%;
  top: -15%;
  border-radius: 326.723px;
  background: linear-gradient(
    132deg,
    #151515 12.72%,
    rgba(21, 21, 21, 0) 89.39%
  );
  z-index: 0;
  filter: blur(32.05009460449219px);
}
