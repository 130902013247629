.banner-circle-bg {
  opacity: 0.2;
  background: radial-gradient(33.54% 50% at 50% 50%, #43b29d 0%, #006284 100%);
  mix-blend-mode: screen;
  filter: blur(125px);
}

.bflow-page-wrapper .swiper-slide {
  height: none !important;
}

.bflow-page-wrapper .shadow-tab-active {
  box-shadow: 0px 0px 25px 0px rgba(0, 128, 255, 0.1);
}

/* 
Delivered more than 10 projects while working in a semi-independent capacity within a fast-paced startup culture.
Implemented brand guidelines and design systems for a consistent interface.
Has been an active participant throughout the project lifecycle.
Developed interactive and pixel-perfect components with React and Next.js.
CSS and JavaScript-based animations: GSAP, Framer Motion, and Tailwind Animations.
CSS and JavaScript-based animations: GSAP, Framer Motion, and Tailwind Animations.
Ensuring web accessibility as per WCA guidelines.
Ensured cross-device, cross-browser, and backward compatibility.
Backend development: Laravel, Node.js, Firebase.
API integrations: React Query, React Redux, Axios.
Client-side state management: Context, React Redux, Redux Toolkit, React Query.
Deployment: Vercel, Netlify, GitHub Pages, DigitalOcean, AWS (Amplify and S3). */
