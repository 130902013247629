.imgslide {
  position: absolute;
  left: 15px;
  right: 15px;
}

.imgslide-enter {
  opacity: 0;
  scale: 0.95;
}

.imgslide-enter-active {
  opacity: 1;
  scale: 1;
  transition: opacity 500ms, scale 500ms;
}

.imgslide-exit {
  opacity: 1;
  scale: 1;
}

.imgslide-exit-active {
  opacity: 0;
  scale: 0.95;
  transition: opacity 500ms, scale 500ms;
}
