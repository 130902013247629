.landing-feature-card::after {
  content: '';
  background-image: linear-gradient(
    132deg,
    #151515 12.72%,
    rgba(21, 21, 21, 0) 89.39%
  );
  position: absolute;
  left: -5%;
  height: 100%;
  width: 75%;
  top: -20%;
  filter: blur(30px);
}
