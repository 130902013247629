.benefits-cards-gradient {
  position: relative;
  overflow: hidden;
  background: black;
  z-index: 1;
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
}
.benefits-cards-gradient::after {
  content: '';
  transition: transform 1s ease;
  position: absolute;
  height: 200%;
  width: 200%;
  top: -50%;
  left: -50%;
  margin: auto;
  background: linear-gradient(
    to right,
    #000 0%,
    #918f8f59 10%,
    #5251514f 20%,
    #d1cfcfad 50%,
    #000
  );
  z-index: -2;
  border-radius: 8px;
  transform: rotate(0deg);
}
.benefits-cards-gradient::before {
  content: '';
  position: absolute;
  overflow: hidden;
  inset: 0;
  z-index: -1;
  margin: 1px;
  border-radius: 8px;
  background: radial-gradient(#171717 0%, #000);
  background-size: cover;
  background-position: -10px -10px;
  transition: all 0.4s ease;
  backdrop-filter: blur(100px);
}

.cards-wrapper .bg-blur-gradient {
  filter: blur(40px);
  opacity: 0;
  transition: all 1s ease;
}
.cards-wrapper:hover .bg-blur-gradient {
  opacity: 1 !important;
}
.cards-wrapper:hover .benefits-cards-gradient::after {
  transform: rotate(125deg);
}
.cards-wrapper:hover .benefits-cards-gradient .text-wrapper {
  transition: all 0.5s ease-in-out;
  transform: translateY(5px);
}
