.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  /* transform-origin: top center; */
  /* transform: scaleY(0); */
}

/* 
.dropdown-content-entering,
.dropdown-content-exiting,
.dropdown-content-exited {
    transform: scaleY(0) !important;
} */

/* .dropdown-content-entering {
    transform: scaleY(1);
    opacity: 1;
} */

/* casestudies carouisel */
.values-carousel .swiper-pagination {
  width: fit-content;
  /* background-color: #252525; */
}

.slide {
  position: absolute;
  inset: 0;
  font-size: 2rem;
}

.slide1 {
  opacity: 0;
}

.slide2 {
  opacity: 0;
}

.slide3 {
  opacity: 0;
}

.background-gradient-img {
  position: relative;
  z-index: 1;
}

/* caseStudiescarosuel */

.case-studies-organism .swiper-wrapper .swiper-slide {
  padding: 10px;
  -webkit-transition: 500ms -webkit-filter linear;
  transition: opacity 500ms ease;
}

.slideActiveClass {
  opacity: 1 !important;
  filter: blur(0) !important;
}

.slideNextClass {
  opacity: 0.5 !important;
  filter: blur(2px) !important;
}

.slidePrevClass {
  opacity: 0.5 !important;
  filter: blur(3px) !important;
}

.swiper-pagination .swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
  height: 10px !important;
  width: 10px;
  transition: all 500ms ease-in-out;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #0080ff !important;
  width: 70px !important;
  height: 10px !important;
  border-radius: 8px;
}

/* Gradient Overlay */

.gradient-overlay .bgImage::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #0000007b;
}