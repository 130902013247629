@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

body,
html {
  font-family: Plus Jakarta Sans;
  background: black;
}

@layer components {
  .d {
    @apply border border-[#ff0000] border-solid;
  }
  .blade-top-padding {
    @apply pt-8 md:pt-10 lg:pt-12  xlg:pt-12 2xl:pt-20  /* needs to be changed*/;
  }
  .blade-top-padding-sm {
    @apply pt-4  md:pt-6  lg:pt-8 xl:pt-10 2xl:pt-14;
  }
  .blade-top-padding-lg {
    @apply pt-10 md:pt-12  lg:pt-16 xl:pt-16 2xl:pt-24;
  }
  .blade-top-padding-xl {
    @apply pt-10 md:pt-16 lg:pt-20 xl:pt-20 2xl:pt-32;
  }
  .blade-bottom-padding {
    @apply pb-8 md:pb-10 lg:pb-12 xlg:pb-12 2xl:pb-20 /* needs to be changed*/;
  }
  .blade-bottom-padding-sm {
    @apply pb-4  md:pb-6  lg:pb-8 xl:pb-10 2xl:pb-14/* needs to be changed*/;
  }
  .blade-bottom-padding-lg {
    @apply pb-10 md:pb-12  lg:pb-16 xl:pb-16 2xl:pb-24; /* needs to be changed*/
  }
  .blade-bottom-padding-xl {
    @apply pb-10 md:pb-12  lg:pb-20 xl:pb-20 2xl:pb-36; /* needs to be changed*/
  }
  .blade-top-margin {
    @apply mt-8 md:mt-10 lg:mt-12  xl:mt-12 2xl:mt-20;
  }
  .blade-top-margin-sm {
    @apply mt-4  md:mt-6  lg:mt-8 xl:mt-10  2xl:mt-14;
  }
  .blade-top-margin-lg {
    @apply mt-10 md:mt-12  lg:mt-16 xl:mt-16  2xl:mt-24;
  }
  .blade-bottom-margin {
    @apply mb-8 md:mb-10 lg:mb-12  xl:mb-12 2xl:mb-20;
  }
  .blade-bottom-margin-sm {
    @apply mb-4  md:mb-6  lg:mb-8 xl:mb-10 2xl:mb-14;
  }
  .blade-bottom-margin-lg {
    @apply mb-10 md:mb-12  lg:mb-16 xl:mb-16 2xl:mb-24;
  }
  .w-container-xs {
    @apply max-w-screen-xl mx-auto w-11/12 md:w-8/12 sm:px-3;
  }
  .w-container-sm {
    @apply max-w-screen-xl mx-auto w-11/12 md:w-10/12 sm:px-3;
  }
  .w-container {
    @apply max-w-screen-2xl mx-auto md:w-11/12 xl:w-10/12 lg:w-11/12 px-3;
  }
  .w-container-lg {
    @apply md:w-11/12 px-3 max-w-screen-2xl mx-auto;
  }
  .w-container-xl {
    @apply md:w-11/12 px-3 xl:px-16 2xl:px-20 max-w-[1920px] mx-auto;
  }
  .text-big {
    @apply 2xl:text-8xl xl:text-7xl text-4xl;
  }
  .input-style {
    @apply !bg-transparent 2xl:text-lg xlg:text-sm text-[12px] focus-visible:bg-transparent focus:bg-transparent placeholder:text-dark focus-within:bg-transparent focus:outline-none font-normal text-black  border-b-[1px] border-[#3A3D45] w-full xlg:py-3 py-2;
  }
  .input-style-light {
    @apply !bg-transparent 2xl:text-lg xlg:text-sm text-[12px] focus-visible:bg-transparent focus:bg-transparent placeholder:opacity-90 focus-within:bg-transparent focus:outline-none font-normal text-white text-opacity-70 border-b-[1px] border-[#3A3D45] w-full xlg:py-2 py-2;
  }
}

@layer base {
  .text-big {
    @apply text-8xl;
  }
  h1 {
    @apply text-[28px] lg:text-[36px] xl:text-[40px] 2xl:text-6xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h2 {
    @apply text-[28px] lg:text-[36px] xl:text-[40px] 2xl:text-[58px] md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  .text-48 {
    @apply text-xl lg:text-[28px] xlg:text-3xl 2xl:text-4xl md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  .text-40 {
    @apply text-2xl lg:text-2xl xlg:text-[30px] 2xl:text-[40px] md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h3 {
    @apply text-2xl lg:text-2xl xlg:text-3xl 2xl:text-[32px] md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h4 {
    @apply text-lg lg:text-xl xlg:text-2xl 2xl:text-[28px] leading-tight md:leading-snug lg:leading-snug 2xl:leading-snug;
  }
  h5 {
    @apply text-sm lg:text-lg  2xl:text-2xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }
  h6 {
    @apply text-sm lg:text-base xl:text-base xlg:text-base 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.text-stroke {
  color: black;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.text-gradient-dark {
  background: linear-gradient(
    150deg,
    rgba(207, 229, 255, 0.6),
    #fff 47.4%,
    rgba(207, 229, 255, 0.6)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
  /* background-size: 200% 200%;
  background-position: 0% 10%; */
}

.bg-dark-gradient {
  background: linear-gradient(
    150deg,
    rgba(30, 29, 29, 0.43) 0.01%,
    #000 30.67%,
    #000 100%
  );
}

.text-gradient-light {
  background: linear-gradient(
    150deg,
    rgba(207, 229, 255, 0.2) -4%,
    #000000 30.4%,
    rgba(207, 229, 255, 0.6) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  background: linear-gradient(180deg, #dbdbdb 0%, #aab1ba 100%);
}
.bg-gradient-light {
  background: linear-gradient(
    118deg,
    rgba(98, 100, 102, 0.78) 6.27%,
    #0080ff29 35.6%,
    rgba(98, 100, 102, 0.78) 80%
  );
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #0080ff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0269cf;
}

::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

/* remove blue active state on mobile*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.swiper-pagination .swiper-pagination-bullet {
  border-radius: 99999px;
}
