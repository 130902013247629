.gallery-layout .gallery-image-0 {
  top: 15%;
  left: 1%;
}
.gallery-layout .gallery-image-1 {
  top: 8%;
  left: 26%;
}
.gallery-layout .gallery-image-2 {
  top: 55%;
  left: 8%;
}
.gallery-layout .gallery-image-3 {
  top: 45%;
  left: 30%;
}
.gallery-layout .gallery-image-4 {
  top: 42%;
  left: 65%;
}
.gallery-layout .gallery-image-5 {
  top: 30%;
  left: 90%;
}
.gallery-layout .gallery-image-6 {
  top: 75%;
  left: 50%;
  height: 14rem;
}
@media (max-width: 1024px) {
  .gallery-layout .gallery-image-6 {
    top: 75%;
    left: 50%;
    height: auto;
  }
}

.bg-ellipse-blur-gradient {
  z-index: 0;
  position: relative;
  inset: 0;
}
.bg-ellipse-blur-gradient::after {
  content: '';
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background: #01010110;
  background-image: url(../../../assets/globals/ellipse1.svg);
  background-size: 40%;
  background-repeat: no-repeat;
  z-index: -1;
}

/* Parallax Effect */

.parallax-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

._slideActiveClass .slides {
  filter: grayscale(0) !important;
  transform: scale(1) !important;
  height: 100% !important;
}

/* ._slideNextClass .slides {
  filter: grayscale(1) !important;
  transform: scale(0.9);
}
._slidePrevClass .slides {
  filter: grayscale(1) !important;
} */

.viewer-slider .swiper-wrapper .swiper-slide {
  -webkit-transition: 500ms -webkit-filter linear;
  transition: opacity 500ms ease;
  transition: transform 500ms ease;
}
